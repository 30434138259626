.no-schedule-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;

  &__title {
    font-size: var(--font-size-m, 16px);
    margin: 30px 0;
    text-align: center;
    padding: 0 40px;
    line-height: 2rem !important;
    font-weight: 500 !important;
  }

  &__bottom-group {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__icon {
    font-size: 50px;
    color: #b3b3b3;
  }

  &__image {
    width: 200px;
  }

  &__cta {
    font-size: var(--font-size-m, 16px) !important;
    border-radius: 12px !important;
    padding: 8px 24px !important;
  }
}
