.kanban-list {
  background-color: var(--color-white-lightest, #ffffff);
  height: 100%;
  padding-right: 8px;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 18px;
    padding: 16px;
    height: 100%;
    background-color: var(--color-gray-purple, #f9f8fc);
  }

  &__preview-card {
    width: 100%;
    height: 2px;
    min-height: 2px;
    background: var(--color-primary, #680ddb);
    border-radius: 8px;
  }

  &__add-task {
    background-color: var(--color-white-lightest, #ffffff);
    padding: 10px;
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 0px 5px var(--color-black-light, #0000001a);
    position: sticky;
    bottom: 0;

    &:disabled {
      cursor: not-allowed;
    }

    &__text {
      font-size: var(--font-size-s, 14px);
      font-weight: var(--font-weight-semi-bold, 500);
      color: var(--color-black, #131314);
    }
    &__icon {
      height: 14px !important;
      width: 18px !important;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

    &__left-group {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: flex-end;
    }

    &__day {
      font-size: var(--font-size-l, 20px);
      color: var(--color-black, #131314);
      font-weight: var(--font-weight-bold, 600);
    }

    &__date {
      font-size: var(--font-size-s, 14px);
      font-weight: var(--font-weight-semi-bold, 500);
      color: var(--color-gray-base, #b5b5bc);
    }
  }

  &__item-group {
    display: flex;
    flex-direction: column;
    padding: 3px;
    gap: 12px;
    overflow-y: hidden;
    position: relative;

    &__tasks {
      display: flex;
      flex-direction: column;
      gap: 8px;
      overflow-y: auto;
    }
  }

  &__loading {
    margin: auto;
  }
}
